import React from "react"
import { getImage, StaticImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'

import Seo from "../components/seo"
import { graphql, useStaticQuery } from "gatsby"

import './index.css'

const Background = ({children, style={}}) => {
  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(relativePath: { eq: "montreal_day.jpeg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1024
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )
  const image = getImage(placeholderImage)

  // Use like this:
  const bgImage = convertToBgImage(image)

  return (
    <BackgroundImage
      Tag="section"
      // Spread bgImage into BackgroundImage:
      {...bgImage}
      preserveStackingContext
      style={{...style}}
    >
      {children}
    </BackgroundImage>
  )
}

const IndexPage = () => (
  <div>
    <Seo title="eliss.io - Accueil" />
    <Background style={{backgroundSize:"cover", width:"100vw", height:"100vh"}}>
      <div style={{display:'flex', flexDirection: 'column', width:"100%", height:"100%", justifyContent:'flex-start', alignItems:'center'}}>
        <StaticImage
          src="../images/white_logo_transparent_background.png"
          loading="eager"
          layout="constrained"
          width={600}
          placeholder="tracedSVG"
          //aspectRatio={1/1}
          quality={95}
          formats={["auto", "webp", "avif"]}
          alt="eliss.io"
          imgStyle={{objectFit:"contain"}}
        />
        <a href="mailto:contact@eliss.io" style={{color:"#ffffff", fontFamily:"helvetica, arial, sans-serif", textDecoration:"none", fontWeight:"bold"}}>Contactez-nous</a>
      </div>
    </Background>
  </div>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage
